import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Accordion from "../components/accordion"
import Imc from "../components/imc"
import ThemeProvider from "@material-ui/styles/ThemeProvider"
import theme from "../theme"
import Typography from "@material-ui/core/Typography"

const Generalities = () => (
  <React.Fragment>
    <ThemeProvider theme={theme}>
      <Layout>
        <SEO title="Généralités" />
        <Typography variant="h4" color="inherit" style={{ margin: "20px 0px" }}>
          Généralités
        </Typography>
        <Accordion
          entries={[
            {
              id: "indications",
              header: "Indications de la chirurgie bariatrique",
              element: (
                <Typography variant="body1" component={"span"}>
                  <div style={{ fontWeight: "bold" }}>
                    La chirurgie bariatrique peut être envisagée chez des
                    patients adultes réunissant l’ensemble des conditions
                    suivantes :
                  </div>
                  <ul>
                    <li>
                      Patients avec un IMC ≥ 40 kg/m², ou bien avec un IMC ≥ 35
                      kg/m² associé à au moins une comorbidité susceptible
                      d’être améliorée après la chirurgie (notamment
                      hypertension artérielle, syndrome d’apnées hypopnées
                      obstructives du sommeil et autres troubles respiratoires
                      sévères, désordres métaboliques sévères, en particulier
                      diabète de type 2, maladies ostéo-articulaires
                      invalidantes, stéatohépatite non alcoolique)
                    </li>
                    <li>
                      En deuxième intention après échec d’un traitement médical,
                      nutritionnel, diététique et psychothérapeutique bien
                      conduit pendant 6 à 12 mois. En l’absence de perte de poids
                      suffisante, ou en l’absence de maintien de la perte de
                      poids
                    </li>
                    <li>
                      Patients bien informés au préalable, ayant bénéficié d’une
                      évaluation et d’une prise en charge préopératoires
                      pluridisciplinaires. Patients ayant compris et accepté la
                      nécessité d’un suivi médical et chirurgical à long terme.
                      Risque opératoire acceptable.
                    </li>
                  </ul>
                  <Imc />
                  <div style={{ fontWeight: "bold" }}>
                    Contre-indications à la chirurgie bariatrique (peuvent être
                    temporaires) :
                  </div>
                  <ul>
                    <li>Les troubles cognitifs ou mentaux sévères</li>
                    <li>
                      Les troubles sévères et non stabilisés du comportement
                      alimentaire
                    </li>
                    <li>
                      L'incapacité prévisible du patient à participer à un suivi
                      médical prolongé
                    </li>
                    <li>
                      La dépendance à l'alcool et aux substances psychoactives
                      licites ou illicites
                    </li>
                    <li>
                      L'absence de prise en charge médicale préalable clairement
                      identifiée
                    </li>
                    <li>
                      Les maladies mettant en jeu le pronostic vital à court et
                      moyen terme
                    </li>
                    <li>Grossesse en cours</li>
                    <li>Les contre-indications à l'anesthésie générale.</li>
                  </ul>
                </Typography>
              ),
            },
            {
              id: "types",
              header: "Les différents types de chirurgie bariatrique",
              element: (
                <Typography variant="body1" component={"span"}>
                  <div>
                    En France, quatre interventions chirurgicales sont validées par la HAS
                    pour la prise en charge de l'obésité :
                  </div>
                  <ul>
                    <li>
                      <a href="/generalities_anneau">
                        Anneau gastrique:
                      </a>
                      &nbsp;Technique restrictive
                    </li>
                    <li>
                      <a href="/generalities_sleeve">
                        Sleeve ou Gastrectomie en manchon:
                      </a>
                      &nbsp;Technique restrictive
                    </li>
                    <li>
                      <a href="/generalities_bypass">
                        By-pass gastrique:
                      </a>
                      &nbsp;Technique restrictive et malabsorptive
                    </li>
                    <li>
                      <a href="/generalities_derivation">
                        Dérivation biliopancréatique:
                      </a>
                      &nbsp;Technique restrictive et malabsorptive
                    </li>
                  </ul>
                </Typography>
              ),
            },
            {
              id: "poid",
              header: "Attentes en terme de poids",
              element: (
                <Typography variant="body1">
                  <div>
                    La chirurgie permet une perte de poids conséquente, mais ne
                    permet qu’exceptionnellement le retour à un IMC dit « normal
                    ». Il est habituel que le poids se stabilise au bout de 12 à
                    18 mois.
                  </div>
                  <br />
                  <div>
                    La perte d’excès de poids attendue à 5 ans est de 50% pour
                    la sleeve et le by-pass gastrique, et de 90% pour la
                    dérivation biliopancréatique.
                  </div>
                </Typography>
              ),
            },
            {
              id: "alimentation",
              header: "L’alimentation après une chirurgie bariatrique",
              element: (
                <Typography variant="body1" component={"span"}>
                  <ul>
                    <li>
                      Ne manger que de petites quantités, possibilité de
                      fractionner les prises alimentaires (il est conseillé
                      d’effectuer 3 petits repas et 2 collations par jour).
                    </li>
                    <li>Bien mastiquer et manger lentement (minimum 30 minutes par repas)</li>
                    <li>
                      Eviter de boire 30 minutes avant le repas, et jusqu’à 30
                      minutes après la fin du repas
                    </li>
                    <li>Ne pas s'allonger après le repas</li>
                    <li>
                      Eviter les boissons gazéifiées ou hypercaloriques (sodas,
                      alcool…)
                    </li>
                    <li>
                      Eviter les aliments riches en sucres simples et/ou en graisses.
                    </li>
                    <li>Eviter les médicaments effervescents.</li>
                  </ul>
                  <p style={{ marginTop: 12 }}>
                    Les aliments prioritaires sont les sources de protéines et
                    calcium. Il faut donc privilégier les produits laitiers,
                    viandes, poissons et œufs.
                  </p>
                </Typography>
              ),
            },
          ]}
        />
      </Layout>
    </ThemeProvider>
  </React.Fragment>
)

export default Generalities
