import React, { useState } from "react"
import TextField from "@material-ui/core/TextField"
import { withStyles } from "@material-ui/core/styles"
import Hidden from "@material-ui/core/Hidden"
import Typography from "@material-ui/core/Typography"

const CustomTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: `4px`,
      },
    },
  },
})(TextField)

const Imc = () => {
  const [poid, setPoid] = useState<number | null>()
  const [taille, setTaille] = useState<number | null>()
  const imcValue =
    poid != undefined && taille != undefined && taille > 0
      ? (poid * 10000) / (taille * taille)
      : null
  return (
    <div>
      <Hidden smDown>
        <div style={{ margin: "30px 20px" }}>
          <div style={{ display: "flex", width: "100%", alignItems: "center" }}>
            <CustomTextField
              id="poid"
              label="Poid (kg)"
              variant="outlined"
              type="number"
              style={{ flex: 1, marginRight: 20 }}
              onChange={e => setPoid(Number(e.target.value))}
            />
            <CustomTextField
              id="taille"
              label="Taille (cm)"
              variant="outlined"
              type="number"
              style={{ flex: 1 }}
              onChange={e => setTaille(Number(e.target.value))}
            />
            <Typography
              component={"span"}
              style={{
                fontSize: 40,
                fontWeight: "bold",
                display: "block",
                flex: 1,
                marginLeft: 20,
              }}
            >
              IMC: {imcValue ? imcValue.toFixed(2) : "N/A"}
            </Typography>
          </div>
        </div>
      </Hidden>
      <Hidden mdUp>
        <div>
          <CustomTextField
            id="poid"
            label="Poid (kg)"
            variant="outlined"
            type="number"
            style={{ width: "100%" }}
            onChange={e => setPoid(Number(e.target.value))}
          />
          <CustomTextField
            id="taille"
            label="Taille (cm)"
            variant="outlined"
            type="number"
            style={{ width: "100%", marginTop: 16 }}
            onChange={e => setTaille(Number(e.target.value))}
          />
          <Typography
            component={"span"}
            style={{
              fontSize: 30,
              fontWeight: "bold",
              display: "block",
              width: "100%",
              textAlign: "center",
              margin: "16px 0px 20px",
            }}
          >
            <div>IMC: {imcValue ? imcValue.toFixed(2) : "N/A"}</div>
          </Typography>
        </div>
      </Hidden>
    </div>
  )
}

export default Imc
